import "../styles/globals.scss";
import { ginger, lora } from "../styles/fonts";
import { Analytics } from '@vercel/analytics/react';
import Head from "next/head";
import Script from "next/script";
import { Toaster } from 'react-hot-toast';
import StoreProvider from "./StoreProvider";
import Layout from "./Layout";

function addSchema() {
    return {
        __html: `{
 
                    "@context": "http://schema.org",
                    "@type": "Organization",
                    "name": "PillUp",
                    "alternateName": "Pill up",
                    "foundingDate": "2022",
                    "url": "https://www.pillup.com/",
                    "logo": "https://www.pillup.com/assets/logo.svg",
                    "description": "PillUp is a modern pharmacy designed to make your life easier.",
                    "contactPoint": [{
                        "@type": "ContactPoint",
                    "telephone": "+919818360666",
                    "email": "hello@pillup.com",
                    "areaServed": ["India"],
 
                    "availableLanguage": ["English", "Hindi"]
            }],
                    "address": {
                    "@context": "https://schema.org",
                    "@type": "PostalAddress",
                    "streetAddress": "307 III-floor, HB Twin Tower-II",
                    "addressLocality": "Netaji Subhash Place, Pitampura",
                    "addressRegion": "Delhi",
                    "postalCode": "110034 ",
                    "addressCountry": "IN"
            }
        }`,
    };
}


function MyApp({ Component, pageProps }) {
    return (
        <StoreProvider>
            {/* <Script
                type="text/javascript"
                src="
            https://d3mkw6s8thqya7.cloudfront.net/integration-plugin.js"
                id="aisensy-wa-widget"
                widget-id="6GNX7W"
            >
            </Script> */}
            <Script id="wati-chat" strategy="afterInteractive">
                {`
                    var searchParams = new URLSearchParams(document.location.search);
                    let utm_source = '';
                    let utm_campaign = '';
                    let utm_medium = '';
                    for (let key of searchParams.keys()) {
                        if (key.toLowerCase().includes('source')) {
                            utm_source = searchParams.get(key);
                        }
                        if (key.toLowerCase().includes('campaign')) {
                            utm_campaign = searchParams.get(key);
                        }
                        if (key.toLowerCase().includes('medium')) {
                            utm_medium = searchParams.get(key);
                        }
                    }
                    if (utm_campaign || utm_source || utm_medium) {
                        fetch('${process.env.NEXT_PUBLIC_BASE_URL}/shortCodeMapping/public', {
                        method: "POST",
                        headers: {
                            'x-api-token': '${process.env.NEXT_PUBLIC_SHORT_CODE_MAPPING_TOKEN}',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            data: {
                                "campaign": utm_campaign,
                                "source": utm_source,
                                "medium": utm_medium
                            },
                            "type": "UTM_PARAMETERS"
                        })
                    })
                    .then(res => {
                        if (!res.ok) {
                            throw new Error('Network response was not ok');
                        }
                        return res.json();
                    })
                    .then(response => {
                        var url = 'https://wati-integration-prod-service.clare.ai/v2/watiWidget.js?71534';
                        var s = document.createElement('script');
                        s.type = 'text/javascript';
                        s.async = true;
                        s.src = url;
                        var options = {
                            "enabled":true,
                            "chatButtonSetting":{
                                "ctaText":"Chat with us",
                                "borderRadius":"25",
                                "marginLeft": "0",
                                "marginRight": "20",
                                "marginBottom": "20",
                                "ctaIconWATI":false,
                                "position":"right"
                            },
                            "brandSetting":{
                                "brandName":"PillUp",
                                "brandSubTitle":"undefined",
                                "brandImg":"https://pillup.com/assets/logo.svg",
                                "welcomeText":"Hi there!\\nHow can I help you?",
                                "messageText":"Hi I am interested in ordering from PillUp",
                                "backgroundColor":"#00e785",
                                "ctaText":"Chat with us",
                                "borderRadius":"25",
                                "autoShow":false,
                                "phoneNumber":"919266607026"
                            }
                        };
                        s.onload = function() {
                            let invitationCode = '';
                            if (response.success ) {
                                invitationCode = response.data.shortCode;
                            }
                            options.brandSetting.messageText = "Hi I am interested in ordering from PillUp." + (invitationCode ? " Invitation Code: " + invitationCode : '');
                            CreateWhatsappChatWidget(options);
                        };
                        var x = document.getElementsByTagName('script')[0];
                        x.parentNode.insertBefore(s, x);
                    })
                    .catch(error => {
                    });
                } else {
                    var url = 'https://wati-integration-prod-service.clare.ai/v2/watiWidget.js?71534';
                        var s = document.createElement('script');
                        s.type = 'text/javascript';
                        s.async = true;
                        s.src = url;
                        var options = {
                            "enabled":true,
                            "chatButtonSetting":{
                                "ctaText":"Chat with us",
                                "borderRadius":"25",
                                "marginLeft": "0",
                                "marginRight": "20",
                                "marginBottom": "20",
                                "ctaIconWATI":false,
                                "position":"right"
                            },
                            "brandSetting":{
                                "brandName":"PillUp",
                                "brandSubTitle":"undefined",
                                "brandImg":"https://pillup.com/assets/logo.svg",
                                "welcomeText":"Hi there!\\nHow can I help you?",
                                "messageText":"Hi I am interested in ordering from PillUp",
                                "backgroundColor":"#00e785",
                                "ctaText":"Chat with us",
                                "borderRadius":"25",
                                "autoShow":false,
                                "phoneNumber":"919266607026"
                            }
                        };
                        s.onload = function() {
                            options.brandSetting.messageText = "Hi I am interested in ordering from PillUp.";
                            CreateWhatsappChatWidget(options);
                        };
                        var x = document.getElementsByTagName('script')[0];
                        x.parentNode.insertBefore(s, x);
                }
                `}
            </Script>
            <Script id="11100836693" strategy="afterInteractive"
                type="application/ld+json"
                dangerouslySetInnerHTML={addSchema()}
                key="product-jsonld"
            />
            <Head>
                <meta property='og:title'
                    content='PillUp - Get Pre-Sorted Medications At Your Door Step along with medicine reminders.' />
                <meta property='og:image' content='https://www.pillup.com/assets/thumbnail.png' />
                <meta property='og:description' content='Switch to the care you deserve' />
                <meta property='og:url' content='https://www.pillup.com' />
                <meta property='og:image:width' content='1200' />
                <meta property='og:image:height' content='627' />
                <meta property="og:type" content='website' />

                <meta charset="UTF-8" />
                <meta name="facebook-domain-verification" content="zqpv3e40le2fquus3fzu1kksxr6swk" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0 , user-scalable=no" />

                <link rel="apple-touch-icon" sizes="180x180" href="/assets/icons/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/assets/icons/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/assets/icons/favicon-16x16.png" />
                <link rel="manifest" href="/assets/icons/site.webmanifest" />
                <link rel="mask-icon" href="/assets/icons/safari-pinned-tab.svg" color="#5bbad5" />
                <meta name="msapplication-TileColor" content="#da532c" />
                <meta name="theme-color" content="#ffffff" />

                <title>PillUp</title>
            </Head>
            <main className={lora.className}>
                <Layout>
                    <Toaster
                        position="top-center"
                        toastOptions={{
                            style: ginger.style
                        }}
                    />
                    <Component {...pageProps} />
                    <Analytics />
                </Layout>
            </main>
        </StoreProvider>
    );
}

export default MyApp;