import React, { useEffect, useState } from 'react'
import { useAppDispatch } from '../store/hooks'
import { getAuthToken, getLoggedInUser, setSessionStorage } from '../utils/helperMethods'
import NavigationSidebar from '../components/global/navigationSidebar/NavigationSidebar'
import { authActions } from '../store/features/auth/authSlice'
import Loader from '../components/global/loader/Loader'
import 'core-js/full/promise/with-resolvers'
import { utmCampaignKey, utmMediumKey, utmSourceKey } from '../constants/constants'

const Layout = ({ children }) => {
    const dispatch = useAppDispatch()

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const token = getAuthToken()
        const user = getLoggedInUser()

        if (token && user) {
            dispatch(authActions.setToken(token))
            dispatch(authActions.setUser(user))
        }

        const searchParams = new URLSearchParams(document.location.search);
        let utm_source = '';
        let utm_campaign = '';
        let utm_medium = '';
        for (let key of searchParams.keys()) {
            if (key.toLowerCase().includes('source')) {
                utm_source = searchParams.get(key);
            }
            if (key.toLowerCase().includes('campaign')) {
                utm_campaign = searchParams.get(key);
            }
            if (key.toLowerCase().includes('medium')) {
                utm_medium = searchParams.get(key);
            }
        }
        if (utm_source) {
            setSessionStorage(utmSourceKey, utm_source)
        }

        if (utm_campaign) {
            setSessionStorage(utmCampaignKey, utm_campaign)
        }

        if (utm_medium) {
            setSessionStorage(utmMediumKey, utm_medium)
        }

        setLoading(false)
    }, [])

    return (
        <>
            {
                loading && <Loader />
            }
            {
                !loading && <>
                    <NavigationSidebar />
                    <div>{children}</div>
                </>
            }
        </>
    )
}

export default Layout